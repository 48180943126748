<template>
    <div>
        <div class="header">
            <h1 class="title">{{ $t('navigation.role') }}
                ({{ total }})
            </h1>
        </div>
        <div class="main-content">
            <!-- <Button class="add" type="primary" icon="ios-add" @click="handleAdd">新建</Button> -->
            <Table class="table" border :columns="columns" :data="list">
                <!-- <template slot-scope="{ row }" slot="action">
                    <span class="action-edit margin-right" @click="handleEdit(row)">编辑</span>
                    <span class="action-remove" @click="handleRemove(row)">删除</span>
                </template> -->
            </Table>
            <Spin size="large" fix v-if="loading"></Spin>
            <Page
                class="page"
                :total="total"
                :current="current"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage" />
        </div>
        <edit-modal
            :show="editModalShow"
            :editInfo="editInfo"
            :type="editType"
            @cancel="handleEditCancel"
            @confirm="handleEditConfirm"
        ></edit-modal>
    </div>  
</template>
<script>
    import editModal from './components/edit'
    import util from '@/libs/util.js'

    export default {
        name: "",
        mixins: [],
        components: {
            editModal
        },
        props: [],
        data () {
            return {
                total: 0,
                current: 1,
                pageSize: 20,
                loading: false,
                columns: [
                    {
                        title: "角色名称",
                        key: "name",
                        align: "center"
                    },{
                        title: "备注",
                        key: "note",
                        align: "center"
                    },{
                        title: "创建用户",
                        key: "user_name",
                        align: "center"
                    },{
                        title: "创建时间",
                        key: "create_time",
                        align: "center"
                    }
                    // ,{
                    //     title: "操作",
                    //     slot: "action",
                    //     align: "center"
                    // }
                ],
                list: [],
                editModalShow: false,
                editInfo: {},
                editType: 'add'
            }
        },
        computed: {},
        methods: {
            handleAdd() {
                this.editType = 'add'
                this.editModalShow = true
            },
            handleEdit(row) {
                this.editType = 'edit'
                this.editInfo = row
                this.editModalShow = true
            },
            handleEditCancel() {
                this.editModalShow = false
            },
            handleEditConfirm() {
                this.handleEditCancel()
                this.getData()
            },
            handleRemove(row) {
                this.$Modal.confirm({
                    title: '温馨提示',
                    content: `您确定将删除角色"${row.name}"吗？`,
                    loading: true,
                    closable: true,
                    onOk: () => {
                        this.$api.delete(`organizations/current/roles/${row.rowid}`).then(() => {
                            this.$Message.success('成功')
                            this.getData()
                        }).finally(() => {
                            this.$Modal.remove()
                        })
                    }
                });
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    query: '',
                    start: (this.current -1) *  this.pageSize,
                    count: this.pageSize,
                    include_super_role: false
                }
                this.loading = true
                this.$api.get('organizations/current/roles/search', { params }).then(({ data, page, total_count }) => {
                    if (this.current === page) {
                        this.total = total_count
                        data.map((item) => {
                            item.create_time = util.handleTime(item.create_time)
                            return item
                        })
                        this.list = data
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";
.main-content {
    position: relative;
    padding: 105px 24px;
    .add {
        position: absolute;
        top: 22px;
        left: 46px;
    }
    .page {
        margin-top: 20px;
    }
    .action-edit, .action-remove {
        display: inline-block;
        color: @primary-color;
        &:hover {
            cursor: pointer;
        }
    }
    .action-edit {
        position: relative;
        padding-right: 10px;
        &::before {
            content: '\200B';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 1px;
            height: 15px;
            background: #5a6875;
        }
    }
} 
</style>