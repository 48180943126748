<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="type === 'add' ? '新建角色' : '编辑角色'"
        :mask-closable="false"
        width="387"
    >
        <Form class="formValidate" ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem label="角色名称" prop="name">
                <Input v-model.trim="formValidate.name"></Input>
            </FormItem>
            <FormItem label="备注" prop="note">
                <Input v-model.trim="formValidate.note"></Input>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            editInfo: {
                default: () => {
                    return {}
                }
            },
            type: {
                default: 'add'
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    name: '',
                    note: ''
                },
                ruleValidate: {
                    name: [
                        { required: true, type: 'string', message: '请输入', trigger: 'blur' },
                        { max: 32, message: '最多32个字符', trigger: 'blur' }
                    ],
                    note: [
                        { required: false, max: 32, message: '最多32个字符', trigger: 'blur' }
                    ]
                }
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val && this.type === 'edit') {
                    this.formValidate.name = this.editInfo.name
                    this.formValidate.note = this.editInfo.note
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.$refs['formValidate'].resetFields();
                this.formValidate = {
                    name: '',
                    note: ''
                }
                this.$emit('cancel')
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        let params = {
                            name: this.formValidate.name,
                            note: this.formValidate.note
                        }
                        this.loading = true
                        if (this.type === 'add') {
                            this.$api.post('organizations/current/roles', params).then(() => {
                                this.$Message.success('成功')
                                this.$emit('confirm')
                                this.cancel()
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {
                            this.$api.put(`organizations/current/roles/${this.editInfo.rowid}`, params).then(() => {
                                this.$Message.success('成功')
                                this.$emit('confirm')
                                this.cancel()
                            }).finally(() => {
                                this.loading = false
                            })
                        }
                    }
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.formValidate {
    width: 80%;
} 
</style>